import React from 'react';
import Layout from "../components/layout";
import Block from "../components/Block";
import People from '../assets/people.png';
import Component from "../components/Component";
const PaymentSuccess = () => (
  <Layout pageTitle="Free trial">
    <div className="page-content">
      <Block className="">
        <Component>
          <h1>Payment recieved</h1>
          <p>Your payment has been received. Thank you for purchasing the Adapt UI Kit.</p>
          <p>You will be able to download the UI Kit via the <a href='https://adapt.tips' target='_blank'>Adapt Tips website</a>. The email used to make the purchase should have received informing them how to sign in and access your product.</p>
          <p>If you did not receive this email or you have any question at all, please email us at <a href='mailto:uikit@adapt.tips'>uikit@adapt.tips</a>.</p>
        </Component>
      </Block>
    </div>
  </Layout>
)
export default PaymentSuccess;
